/* Global CSS */
html,
body {
  height: 100%;
  width: 100%;
  background-color: #F9F9F9;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.fontLoaded {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ui.sortable.table thead th:after {
  vertical-align: baseline;
}

.hide-scrollbar {
	overflow: -moz-hidden-unscrollable;
	overflow: hidden;
}
